<template>
  <div class="w-11.5/12 bg-lightGrey m-auto my-5 py-5 rounded-lg">
    <div class="flex flex-col w-11/12 m-auto">
      <h1 class="text-grey font-bold ml-10 mb-3">Specification Name</h1>

      <!-- Basic Info -->
      <ul class="ml-10 mt-8 lg:grid w-1/2">
        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Title:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>

        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Salary Grade:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Rota Name:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Total Hours:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Location:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-darkGrey">Job Purpose:</p>
          <p class="text-grey">Lorem ipsum dolor sit amet.</p>
        </div>
      </ul>
    </div>
    <!-- Responsibilities -->
    <div class="flex flex-col w-11/12 m-auto">
      <div class="flex justify-between items-center text-grey">
        <h1 class="ml-10 font-semibold my-3">Responsibilities:</h1>
        <div class="flex">
          <p class="text-darkGrey">Add Row</p>
          <span
            @click="addResponsibility"
            class="material-icons text-orange ml-3 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
          >
            add_circle
          </span>
        </div>
      </div>
      <table>
        <tbody>
          <tr
            v-for="(responsibility, index) in responsibilities"
            :key="responsibility.id"
            class=""
          >
            <td class="text-orange px-2 bg-lightGrey">
              <div class="flex justify-center">
                <span
                  @click="deleteResponsibility(index)"
                  class="material-icons w-6 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
                >
                  remove_circle
                </span>
              </div>
            </td>
            <td class="py-4 px-6 text-sm text-center text-grey border bg-white">
              {{ responsibility.id }}
            </td>
            <td class="py-4 px-6 text-sm text-grey border w-full bg-white pointer-events-none">
              <input
                class="w-full"
                type="text"
                v-model="responsibility.responsibility"
                :disabled="true"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Person Specification -->
    <div class="flex flex-col w-11/12 m-auto">
      <div class="flex justify-between items-center text-grey">
        <h1 class="ml-10 font-semibold my-3">Person Specification:</h1>
        <div class="flex">
          <p class="text-darkGrey">Add Row</p>
          <span
            @click="addPersonSpec()"
            class="material-icons text-orange ml-3 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
          >
            add_circle
          </span>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th class="py-1 px-2"></th>
            <th
              scope="col"
              class="border py-3 px-6 text-xs text-left text-teal bg-white"
            >
              Attributes
            </th>
            <th
              scope="col"
              class="border py-3 px-6 text-xs text-left text-teal bg-white"
            >
              Essential
            </th>
            <th
              scope="col"
              class="border py-3 px-6 text-xs text-left text-teal bg-white"
            >
              Desirable
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(person, index) in personSpec" :key="person.id" class="">
            <td class="text-orange py-1 px-2">
              <div class="flex justify-center">
                <span
                  @click="deletePersonSpec(index)"
                  class="material-icons w-6 cursor-pointer hover:scale-110 transform-gpu transition duration-100 ease-linear"
                >
                  remove_circle
                </span>
              </div>
            </td>
            <td class="py-4 px-2 text-sm text-teal border bg-white">
              <input class="w-full pointer-events-none" type="text" v-model="person.attribute" />
            </td>
            <td
              class="py-4 px-6 text-sm font-medium text-grey border bg-white w-1/2"
            >
              <input class="w-full pointer-events-none" type="text" v-model="person.essential" />
            </td>
            <td
              class="py-4 px-6 text-sm font-medium text-grey border bg-white w-1/2"
            >
              <input class="w-full pointer-events-none" type="text" v-model="person.desirable" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // this.fetchPersonSpecifications();
  },
  data() {
    return {
      isLoading: false,
      responsibilities: [
        {
          id: 1,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 2,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 3,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 4,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
        {
          id: 5,
          responsibility:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
        },
      ],
      personSpec: [
        {
          id: 1,
          attribute: "Education",
          essential: "PhD",
          desirable: "GCSE",
        },
        {
          id: 2,
          attribute: "Experience",
          essential: "SystmOne",
          desirable: "Other Medical System",
        },
        {
          id: 3,
          attribute: "Ability",
          essential: "Vaccine",
          desirable: "Covid Vaccine",
        },
        {
          id: 4,
          attribute: "Equal Opportunities",
          essential: "Be Equal",
          desirable: "Be Super Equal",
        },
      ],
    };
  },
  methods: {
    addResponsibility() {
      this.responsibilities.push({
        id: "",
        responsibility: "",
      });
    },
    deleteResponsibility(index) {
      this.responsibilities.splice(index, 1);
    },
    addPersonSpec() {
      this.personSpec.push({
        id: "",
        attribute: "",
        essential: "",
        desirable: "",
      });
    },
    deletePersonSpec(index) {
      this.personSpec.splice(index, 1);
    },
    // async fetchPersonSpecifications() {
    //   try {
    //     this.isLoading = true;
    //     await this.$store.dispatch("HQnewHire/fetchPersonSpecifications", [
    //       {
    //         status: status,
    //         page,
    //       },
    //     ]);
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.error = error.message || "Something went wrong!";
    //   }
    // },
  },
};
</script>
